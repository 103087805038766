.main-content {
    margin-left:15rem;
    margin-top:50px;
    padding:1rem;

}

.left-menu {
    width:14rem;
    position:absolute;
    left:0em;
    height:100%;
    margin-top:0px;
    padding:1rem;
    background-color: #ddd;
}